/*!
 * LAPP Developer Styleguide - version 0.2.0 from Fri Dec 17 2021 10:24:09 GMT+0100 (CET)
 *  mode: production / platform: hybris
 */
@-ms-viewport {
  width: device-width;
}

@font-face {
  font-family: "Source Sans Pro";
  src: url(../public/fonts/SourceSansPro-Regular.ttf);
}

html #root,
html #fluent-default-layer-host {
  box-sizing: border-box;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  text-align: left;
  -ms-overflow-style: scrollbar;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App {
  margin: 0 auto;
  max-width: 1168px;
}

#cui,
#cui > div,
#cui > div > div,
#encoway-configurator,
#encoway-configurator > div {
  height: 100%;
}

#root *,
#root *:after,
#root *:before {
  box-sizing: border-box;
}

#root .padding-1 {
  padding: 1.5em;
}

#root footer a {
  text-decoration: none;
}

#root footer a:hover {
  text-decoration: underline;
}

#root footer .is-flex {
  display: flex;
}

#root footer .columns {
  display: flex;
  flex-direction: row;
}

#root footer .column {
  padding: 0.5em;
  flex: 1;
}

#root footer .is-multiline {
  flex-flow: wrap;
}

#root footer > div {
  max-width: 960px;
  margin: 0 auto;
}

#root .disable-input .react-select__input {
  display: none;
}

#encoway-configurator .parameter ~ .ms-Stack > .ms-StackItem:last-child > i {
  display: none;
}

#encoway-configurator .parameter ~ .ms-Stack > .ms-StackItem:first-child {
  margin-right: 0;
}

#encoway-configurator .ms-TextField-fieldGroup {
  height: 38px;
}

#root .navbar {
  position: relative;
  padding: 1.8em 1.5em;
  height: 111.6px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

#root .navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

#root .navbar-brand:focus,
#root .navbar-brand:hover {
  text-decoration: none;
}

.sui-text-link::before,
a::before {
  content: "";
  display: block;
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

#root,
#fluent-default-layer-host {
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
}

#root select,
#root textarea,
#root input,
#fluent-default-layer-host select,
#fluent-default-layer-host textarea,
#fluent-default-layer-host input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
  box-sizing: border-box;
}

@keyframes custom-lapp-spinner {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
